import { ICellRendererParams } from '@ag-grid-community/core';
import dayjs from 'dayjs';

export function cellRenderDateFormatter(params: ICellRendererParams): string {
  return formatStringDate(params.value);
}
export function formatStringDate(
  date: string | Date,
  format = 'DD/MM/YYYY'
): string {
  return date ? dayjs(date).format(format) : '';
}

export function formatDateToUTC(date: string | Date): Date {
  const newDate = date && typeof date !== 'string' ? date : new Date(date);
  return new Date(
    Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 0, 0)
  );
}
